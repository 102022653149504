import React from "react";
import styled from "styled-components";
import { Section, Container } from "../global";
import { ADDRESS } from "../const";
const Map = () => {
  const googleMap =
    "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2443.661965882946!2d21.01997121579677!3d52.231358979760536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWarszawa%2000-400%20ul.%20Nowy%20%C5%9Awiat%206%2F12%2C%20klatka%20B%2C%20V%20pi%C4%99tro%2C%20lokal%20536!5e0!3m2!1spl!2spl!4v1620142020968!5m2!1spl!2spl";
  // const googleMap = `https://maps.google.com/maps?width=100%25&amp;&amp;hl=en&amp;q=${ADDRESS.address}&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`;
  return (
    <MapWrapper src='https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2443.661965882946!2d21.01997121579677!3d52.231358979760536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWarszawa%2000-400%20ul.%20Nowy%20%C5%9Awiat%206%2F12%2C%20klatka%20B%2C%20V%20pi%C4%99tro%2C%20lokal%20536!5e0!3m2!1spl!2spl!4v1620142020968!5m2!1spl!2spl'></MapWrapper>
  );
};
export default Map;

const MapWrapper = styled.iframe`
  border: 0;
  width: 100%;
  height: 300px;
  padding: 0;
  margin: 0;
`;
