import React, { Component } from "react";
import styled from "styled-components";
import { Button } from "../global";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { ADDRESS } from "../const";
class Form extends Component {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", message: "", status: "" };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCloseAlert = () => {
    this.setState({ name: "", email: "", message: "", status: "" });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    console.log(data);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: true });
      } else {
        this.setState({ status: false });
      }
    };
    xhr.send(data);
    setTimeout(this.handleCloseAlert, 7000);
  };

  render() {
    const { name, email, message } = this.state;
    return (
      <FormWrapper
        name='contact'
        onSubmit={this.handleSubmit}
        action='https://formspree.io/f/xzbydkdy'
        method='POST'>
        <Column>
          <FormInput>
            <label>Imię i Nazwisko: </label>
            <input
              type='text'
              name='name'
              value={name}
              id='name'
              placeholder='Imię i Nazwisko'
              required
              onChange={this.handleChange}
            />
          </FormInput>
          <FormInput>
            <label>Email: </label>
            <input
              type='text'
              id='mail'
              name={email}
              value={email}
              placeholder='E-mail'
              required
              name='email'
              onChange={this.handleChange}
            />
          </FormInput>
        </Column>

        <Column>
          <FormInput>
            <label>Message: </label>
            <textarea
              name='message'
              id='message'
              placeholder='Wpisz wiadomość'
              required
              value={message}
              onChange={this.handleChange}></textarea>
          </FormInput>
        </Column>
        <Rodo>
          <label htmlFor='rodo'>
            <input type='checkbox' id='rodo' required />
            <p>
              ZGODA NA PRZETWARZANIE DANYCH OSOBOWYCH. Na podstawie art. 6 ust 1
              lit. a Rozporządzenia Parlamentu Europejskiego i Rady (UE)
              2016/679/UE z 27.04.2016 r. w sprawie ochrony osób fizycznych w
              związku z przetwarzaniem danych osobowych i w sprawie swobodnego
              przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
              rozporządzenie o ochronie danych) (Dz.Urz. UE L 119, s. 1)
              oświadczam że wyrażam zgodę na przetwarzanie oraz udostępnianie
              moich danych osobowych. Zapoznaj się z naszą&nbsp;
              <AnchorLink to='/polityka-prywatnosci'>
                polityką prywatnosći
              </AnchorLink>
            </p>
          </label>
        </Rodo>
        <Button secondary type='submit'>
          Wyślij wiadomość
        </Button>

        {this.state.status ? (
          <AlertInfo>
            <p>Dziękujemy za Twoją wiadomość</p>
            <p>Postaramy się odpowiedzieć tak szybko jak to będzie możliwe.</p>
            <Close onClick={this.handleCloseAlert}>X</Close>
          </AlertInfo>
        ) : null}

        {this.state.status === false ? (
          <AlertInfo>
            <p>Wystąpił problem z wysłaniem wiadomości</p>
            <p>Prośba o kontakt na adres: {ADDRESS.mail}</p>
            <Close onClick={this.handleCloseAlert}>X</Close>
          </AlertInfo>
        ) : null}
      </FormWrapper>
    );
  }
}

export default Form;
const Rodo = styled.div`
  padding: 10px;
  label {
    display: flex;
  }

  label > p {
    font-size: ${(props) => props.theme.font.size.xxxxsmall};
    width: 100%;
    text-align: left;
    padding-left: 10px;
    a {
      color: ${(props) => props.theme.color.white.whiteRegular};
    }
  }
`;
const Close = styled.div`
  position: absolute;
  right: 0px;
  top: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: ${(props) => props.theme.color.primary};
  font-weight: ${(props) => props.theme.font.weight.regular};
`;
const AlertInfo = styled.div`
  position: fixed;
  top: 100px;
  left: 50%;
  top: 35%;
  width: 80%;
  transform: translate(-50%, -50%);
  z-index: 20000000000000;
  background-color: ${(props) => props.theme.color.background};
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  p {
    color: ${(props) => props.theme.color.black.black};
    font-weight: ${(props) => props.theme.font.weight.regular};
  }
`;
const FormWrapper = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 60%;
  margin: 0 auto;
  padding: 50px 0px;
  button {
    margin: 30px auto 0;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.screen.xs}) {
    button {
      padding: 7px 20px;
    }
  }
`;

const FormInput = styled.div`
  width: 100%;
  padding: 10px 11px;

  input,
  textarea {
    border-radius: 2px;
    width: 100%;
    border: 0;
    height: 35px;
    outline: none;
    padding: 10px;
    ::placeholder {
      color: ${(props) => props.theme.color.primary};
      font-size: ${(props) => props.theme.font.size.xxsmall};
    }
  }
  input {
    height: 35px;
  }
  textarea {
    min-height: 90px;
  }
  label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    /* padding: 30px; */
    width: 100%;
    button {
      margin: 0 auto;
    }
  }
`;
