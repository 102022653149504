import React from "react";
import styled from "styled-components";
import { Section, Container, Flex } from "../global";
import { Phone, Mail, MapPin } from "react-feather";
import { ADDRESS } from "../const";
import Logo from "../common/logo";
import Form from "../form/form";
const Contact = () => {
  return (
    <ContactWrapper id='contact'>
      <h4>Kontakt</h4>
      <Container>
        <Address>
          <Logo />
          <br />
          <br />
          {ADDRESS.address}
          <br />
          {ADDRESS.address2}
          <br />
          <br />
          <a href={`tel:${ADDRESS.phone1}`}>
            {
              <div>
                <Phone size={15} /> {ADDRESS.phone1}
              </div>
            }
          </a>
          <a href={`tel:${ADDRESS.phone2}`}>
            {
              <div>
                <Phone size={15} /> {ADDRESS.phone2}
              </div>
            }
          </a>
          <a href={`mailto:${ADDRESS.mail}`}>
            {
              <div>
                <Mail size={15} /> {ADDRESS.mail}
              </div>
            }
          </a>
        </Address>
        <Form />
        <ContactInfo>
          <a
            href={`http://maps.google.com/?q=${ADDRESS.address}+${ADDRESS.address2}`}
            target='_blank'>
            {
              <div>
                <MapPin size={15} /> {ADDRESS.address}
                <br />
                {ADDRESS.address2}
              </div>
            }
          </a>
          <Phones>
            <a href={`tel:${ADDRESS.phone1}`}>
              {
                <div>
                  <Phone size={15} /> {ADDRESS.phone1}
                </div>
              }
            </a>

            <a href={`tel:${ADDRESS.phone2}`}>
              {
                <div>
                  <Phone size={15} /> {ADDRESS.phone2}
                </div>
              }
            </a>
          </Phones>
          <a href={`mailto:${ADDRESS.mail}`}>
            {
              <div>
                <Mail size={15} /> {ADDRESS.mail}
              </div>
            }
          </a>
        </ContactInfo>
      </Container>
    </ContactWrapper>
  );
};

export default Contact;
const Address = styled.address`
  color: ${(props) => props.theme.color.white.whiteRegular};
  font-weight: ${(props) => props.theme.font.weight.light};
  text-align: center;
  font-style: normal;
  line-height: 1.5;

  a {
    color: ${(props) => props.theme.color.white.whiteRegular};
    &:hover {
      color: ${(props) => props.theme.color.white.whiteDarker};
    }
    svg {
      margin-bottom: -2px;
      margin-right: 5px;
    }
  }
`;
const ContactWrapper = styled(Section)`
  background-color: ${(props) => props.theme.color.accent};
  h4 {
    color: ${(props) => props.theme.color.white.whiteRegular};
    &::before,
    &::after {
      border-color: ${(props) => props.theme.color.white.whiteRegular};
    }
  }
  p {
    width: 60%;
    margin: 0 auto;
    color: ${(props) => props.theme.color.white.whiteDarker};
    font-size: ${(props) => props.theme.font.size.xsmall};
    text-align: center;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;
const Phones = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ContactInfo = styled(Flex)`
  /* width: 100%; */
  margin: 0 auto;
  justify-content: center;
  a,
  a:visited {
    /* border: 1px solid red; */
    text-align: center;
    min-width: 300px;
    display: block;
    padding: 5px 10px;
    /* min-width: 120px; */
    font-size: ${(props) => props.theme.font.size.xxsmall};
    color: ${(props) => props.theme.color.white.whiteRegular};
    svg {
      margin-bottom: -2px;
      margin-right: 5px;
    }
    @media (max-width: ${(props) => props.theme.screen.xs}) {
      padding: 10px 5px;
      white-space: nowrap;
    }
    &:hover {
      color: ${(props) => props.theme.color.white.whiteDarker};
    }
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    flex-wrap: wrap;
  }
`;
